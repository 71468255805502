<template>
  <b-row>
    <b-col cols="12" xl="3" class="nopadding">
      <b-card>
        <div class="text-center">
          <img v-if="supply&&supply.logo" :src="computed_logo(supply.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar" id="fileControl_supply">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar" id="fileControl_supply">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_name">{{$t('common.name')}}</label>
              <div v-if="$v.supply.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.supply.name.required">{{$t('common.name')}}</div>
                  <div class="text-danger" v-else-if="!$v.supply.name.isUnique">{{$t('common.name_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_name')"
              v-model="supply.name" maxlength="50" ref="txt_supply_name" size="sm"
              v-on:input="$v.supply.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_code">{{$t('common.code')}}</label>
              <div v-if="$v.supply.code.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.supply.code.required">{{$t('common.code_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.supply.code.isUnique">{{$t('common.code_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_code" :placeholder="$t('common.placeholder_code')"
              v-model="supply.code" maxlength="25" size="sm"
              v-on:input="$v.supply.code.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_phone">{{$t('common.phone')}}</label>
              <div v-if="$v.supply.phone.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.supply.phone.numeric">{{$t('common.phone_invalid')}}</div>
                <div class="text-danger" v-else-if="!$v.supply.phone.minLength">{{$t('common.phone_ten_number')}}</div>
              </div>
              <b-form-input type="text" id="txt_phone"
              v-on:input="$v.supply.phone.$touch" size="sm"
              :placeholder="$t('common.placeholder_phone')"
              v-model="supply.phone" maxlength="15"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_email">{{$t('common.email')}}</label>
              <div v-if="$v.supply.email.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.supply.email.email">{{$t('common.email_invalid')}}</div>
              </div>
              <b-form-input type="text" id="txt_email" :placeholder="$t('common.placeholder_email')"
              v-model="supply.email" maxlength="50" size="sm"
              v-on:input="$v.supply.email.$touch"></b-form-input>
            </b-form-group>
            <b-form-group v-if="isSuper">
              <label for="input_company">{{$t('common.company')}}</label>
              <div v-if="$v.supply.company_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.supply.company_id.required">{{$t('common.company_required')}}</div>
              </div>
              <b-form-select id="input_company" v-model="supply.company_id"
                v-on:input="$v.supply.company_id.$touch">
                  <option value="null" disabled>{{$t('common.select_company')}}</option>
                  <option v-for="d in ls_company_ddl" :value="d"
                      v-bind:key="d.id">{{d.name}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="isSuper">
              <label for="ddl_branch">{{$t('common.branch')}}</label>
              <div v-if="$v.supply.branch_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.supply.branch_id.required">{{$t('common.branch_required')}}</div>
              </div>
              <b-form-select id="ddl_branch" v-model="supply.branch_id"
                v-on:input="$v.supply.branch_id.$touch">
                  <option value="null" disabled>{{$t('common.select_branch')}}</option>
                  <option v-for="d in ls_branch_ddl" :value="d"
                      v-bind:key="d.value">{{d.name}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="isSuper">
              <label for="ddl_business">{{$t('common.business')}}</label>
              <div v-if="$v.supply.business.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.supply.business.required">{{$t('common.business_required')}}</div>
              </div>
              <b-form-select id="ddl_business" v-model="supply.business"
                v-on:input="$v.supply.business.$touch">
                  <option value="null" disabled>{{$t('common.select_business')}}</option>
                  <option v-for="d in options_business" :value="d"
                      v-bind:key="d.value">{{d.name}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="isSuper">
              <label for="chkcocon">{{$t('common.company')}}</label>
              <b-form-checkbox switch size="lg" id="chkcocon"
              v-model="supply.cocon"></b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <label for="txt_address">{{$t('common.address')}}</label>
              <b-form-textarea type="text" id="txt_address"
              v-model="supply.address" maxlength="200"
              :placeholder="$t('common.placeholder_address')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-info" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button class="ml-2" size="sm" variant="outline-success" @click.prevent="saveSupply()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="9" class="nopadding">
      <transition name="slide">
      <b-card>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <label>{{data.item.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
           <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                <i class="fa fa-recycle"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>

    <b-modal id="viewModal" :title="$t('common.message')" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,email,url,numeric }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { uuid } from 'vue-uuid'

export default {
  name: 'Supply',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      options_business: [],
      supply:{
        id:null,
        business:null,
        company_id:null,
        branch_id:null,
        name:null,
        phone:null,
        email:null,
        code:moment().valueOf().toString(),
        logo:null,
        address:null,
        user_create_id:null
      }
    }
  },
  validations: {
    supply: {
      name:{
        required,
        maxLength:maxLength(50),
        async isUnique(value) {
          if (value==null) return true
          if (this.company_id==null) return false
          if(this.supply && this.supply.id!=null) return true
          const res = await this.nameUnique({company_id:this.user_login.company_id,name:value})
          debugger
          return Boolean(res.data)
        }
      },
      code:{
        required,
        maxLength:maxLength(25),
        async isUnique(value) {
          if (value==null) return true
          if (this.company_id==null) return false
          if(this.supply && this.supply.id!=null) return true
          const res = await this.codeUnique({company_id:this.user_login.company_id,code:value})
          debugger
          return Boolean(res.data)
        }
      },
      email:{
        email,maxLength:maxLength(50)
      },
      phone:{
        minLength:minLength(10),
        numeric
      },
      company_id:{
        required: requiredIf(function () {
          return this.isSuper
        })
      },
      branch_id:{
        required: requiredIf(function () {
          return this.isSuper
        })
      },
      business:{
        required: requiredIf(function () {
          return this.isSuper
        })
      }
    }
  },
  computed: {
    ...mapGetters('st_supply', ['ls_supply','page_count','page','filter','per_page']),
    fields(){
      let fields=[]

      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      if(user.role==Role.Admin){
        fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      }

      fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true, variant:'primary'})
      fields.push({key: 'code',label:this.$t('common.code'),sortable: true})
      fields.push({key: 'email',label:this.$t('common.email'),sortable: true})
      fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'address',label:this.$t('common.address'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    company_id(){
      let user=this.$store.state.st_authentication.user
      return user.company_id
    },
    isSuper() {
      let user=this.$store.state.st_authentication.user
      return user&&user.role==Role.SuperAdmin
    },
    ls_company_ddl:{
      get: function(){
        return this.$store.state.st_supply.ls_company_ddl
      }
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    ls_branch_ddl:{
      get: function(){
        return this.$store.state.st_supply.ls_branch_ddl
      }
    },
    filter:{
      get: function(){
        return this.$store.state.st_supply.filter
      },
      set: function(text){
        this.$store.commit('st_supply/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_supply.ls_supply
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    async nameUnique(param){
      return await this.$store.dispatch(`st_supply/name_unique`,param)
    },
    async codeUnique(param){
      return await this.$store.dispatch(`st_supply/code_unique`,param)
    },
    ...mapActions('st_supply', ['get_ls_supply','change_page', 'apply_filter']),
      ...mapActions('st_company', ['get_ls_company_ddl']),
      ...mapActions('st_branch', ['get_ls_branch_ddl']),
    clear(){
      this.supply={id:null,logo:null,name:null,code:moment().valueOf().toString(),address:null,
      email:null,phone:null,company_id:null,branch_id:null,user_create_id:null,user_update_id:null,
      business:null}
      this.$refs.txt_supply_name.$el.focus()
      this.$v.$reset()
    },
    rowDeleted(item, type){
      debugger
      if (!item || type !== 'row') return
      debugger
      if (item.status === -1) return 'table-danger'
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },

    async saveSupply() {
      const isValid = await this.isValid()
      if (!isValid) {
          return
      }

      var app = this
      if (app.processing == true) {
        return
      }

      // set the async state
      app.processing = true

      if(app.supply.id){
        app.supply.user_update_id=app.user_login.id
        app.$store.dispatch('st_supply/update',app.supply)
        .then(function (resp) {
          app.get_ls_supply()
          app.clear()
          app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        app.supply.user_create_id=app.user_login.id
        if(!app.isSuper){
          app.supply.company_id=app.user_login.company_id
          app.supply.business=app.user_login.company.business
        }

        app.$store.dispatch('st_supply/create',app.supply)
        .then(function (resp) {
          app.get_ls_supply()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      debugger
      this.supply=item
    },
    searchLike:function(item){
      return item.name.includes(this.filter)
      ||item.phone&&item.phone.includes(this.filter)
      ||item.code&&item.code.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
    },
    getImage:function(e){
      let app=this
      debugger
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl_supply").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.supply.logo=e.target.result
      }
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_supply/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_supply()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async deleteObj(obj) {
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.supply")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_supply/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_supply()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
  },
  mounted () {
    let vm=this
    vm.$refs.txt_supply_name.$el.focus()
    vm.options_business=vm.option_business
    vm.get_ls_supply()
    vm.get_ls_company_ddl(vm.user_login.company_id)
    vm.get_ls_branch_ddl(vm.user_login.company_id)
  }
}
</script>
